import { withPrefix } from "gatsby"
import React, { useEffect, useRef, useState } from "react"
import useWindowSize from "../hooks/useWindowSize"

enum screenSize {
  mobile,
  desktop,
}

const Video = ({ mobileSrc, desktopSrc, muted = true }) => {
  const videoRef = useRef<HTMLVideoElement>()
  const sizes = useWindowSize()
  const [currentScreenSize, setCurrentScreenSize] = useState<screenSize>(
    sizes?.width <= 768 ? screenSize.mobile : screenSize.desktop
  )

  useEffect(() => {
    const newScreenSize =
      sizes?.width <= 768 ? screenSize.mobile : screenSize.desktop
    if (newScreenSize != currentScreenSize) {
      setCurrentScreenSize(newScreenSize)
      videoRef?.current?.load()
    }
  }, [sizes, currentScreenSize])

  return (
    <video
      autoPlay
      muted={muted}
      loop
      playsInline
      ref={videoRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        height: "100%",
        width: "100%",
        zIndex: "-1",
        objectFit: "cover",
        objectPosition: "center",
      }}
    >
      <source
        src={withPrefix(sizes?.width <= 768 ? mobileSrc : desktopSrc)}
        type="video/mp4"
      />
      Your device does not support playing 'video/mp4' videos
    </video>
  )
}

export default Video

import * as React from "react"
// @ts-ignore
import soundOffSVG from "../images/sound-off.svg"
// @ts-ignore
import soundOnSVG from "../images/sound-on.svg"

const SoundButton = ({ muted = true, toggleSound }) => {
  return (
    <div
      className="sound-button"
      tabIndex={0}
      role="button"
      onClick={toggleSound}
      onKeyPress={event => {
        if (event.key === "Enter") toggleSound()
      }}
    >
      <img
        src={muted ? soundOffSVG : soundOnSVG}
        alt="Enable or disable video sound"
      />
    </div>
  )
}

export default SoundButton

import { withArtDirection } from "gatsby-plugin-image"

const getImagesMobile = (mobileImage, tabletImage) => {
  return withArtDirection(tabletImage, [
    {
      media: "(max-width: 443px)",
      image: mobileImage,
    },
  ])
}

export default getImagesMobile

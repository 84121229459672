import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { useSwipeable } from "react-swipeable"
import findImage from "../utils/findImage"
import getImagesMobile from "../utils/getImagesMobile"
import getSlugByKey from "../utils/getSlugByKey"

const CategoriesCarousel = ({ refCategories }) => {
  const [index, setIndex] = React.useState(0)

  const { t } = useTranslation()

  const categories = [
    {
      name: t("menu.beauty"),
      path: "beauty",
      img: "carousel-beauty.jpg",
      color: "rgba(100,0,0,0.5)",
    },
    {
      name: t("menu.sex"),
      path: "sex",
      img: "carousel-sex.jpg",
      color: "rgba(0,100,0,0.5)",
    },
  ]

  const { language } = useI18next()

  const data = useStaticQuery(graphql`
    query EditorialPathsCategoriesQuery {
      allEditorialJson {
        edges {
          node {
            slug
            key
            language
          }
        }
      }
      allCategoryJson {
        edges {
          node {
            slug
            key
            language
          }
        }
      }
      mobileImages: allFile(
        filter: { sourceInstanceName: { eq: "content-images" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
      tabletImages: allFile(
        filter: { sourceInstanceName: { eq: "content-images-tablet" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
  `)

  const config = {
    onSwipedRight: eventData => {
      console.log("onSwipedRight", eventData)
      setIndex(prevIndex => (prevIndex - 1 < 0 ? 0 : prevIndex - 1))
    },
    onSwipedLeft: eventData => {
      console.log("onSwipedLeft", eventData)
      setIndex(prevIndex =>
        prevIndex + 1 === categories?.length ? prevIndex : prevIndex + 1
      )
    },
  }

  const handlers = useSwipeable(config)

  return (
    <div className="cat-carousel" ref={refCategories}>
      <div
        className="cat-carousel--container"
        style={{
          left: `${-100 * index}vw`,
          width: `${100 * categories?.length}vw`,
        }}
        {...handlers}
      >
        {categories.map(({ name, img, path }, currentIndex) => {
          const images = getImagesMobile(
            findImage(data.mobileImages, img),
            findImage(data.tabletImages, img)
          )

          return (
            <Link
              key={`cat-${currentIndex}`}
              to={getSlugByKey(data, path, language)}
              style={{ textDecoration: "none" }}
            >
              <div className="cat-carousel--slide" key={name}>
                <GatsbyImage
                  className="editorial--background"
                  image={images}
                  alt={name}
                />
                <div className="cat-carousel--label">{name}</div>
              </div>
            </Link>
          )
        })}
      </div>
      <div className="cat-carousel--dots">
        {categories.map((_, currentIndex) => (
          <span
            className={`dot ${index === currentIndex ? "active" : ""}`}
            key={currentIndex}
          />
        ))}
      </div>
    </div>
  )
}

export default CategoriesCarousel

import { graphql, useStaticQuery } from "gatsby"
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import Scroll from "react-scroll"
import { useSwipeable } from "react-swipeable"
import Seo from "../components/seo"
import Video from "../components/video"
import useWindowSize from "../hooks/useWindowSize"
// @ts-ignore
import arrowDownSVG from "../images/arrow-down.svg"
import getSlugByKey from "../utils/getSlugByKey"
import CategoriesCarousel from "./CategoriesCarousel"
import MenuBottom from "./menuBottom"
import SoundButton from "./soundButton"

const Home = () => {
  const [videoMuted, setVideoMuted] = React.useState(true)
  const refCategories = React.useRef<HTMLElement>()
  const setFocus = () => refCategories.current && refCategories.current.focus()
  const { t } = useTranslation()
  const { language } = useI18next()

  const sizes = useWindowSize()
  const showCarousel = sizes?.width <= 1025

  var Element = Scroll.Element
  var scroller = Scroll.scroller

  const scroll = element => {
    scroller.scrollTo(element, {
      duration: 700,
      delay: 0,
      smooth: true,
    })
  }

  function scrollToCategories() {
    if (showCarousel) {
      setVideoMuted(true)
      setFocus()
      scroll("categories")
    }
  }

  const config = {
    onSwipedUp: eventData => {
      console.log("onSwipedUp", eventData)
      scrollToCategories()
    },
    // onSwipedDown: eventData => {
    //   console.log("onSwipedDown", eventData)
    //   scroll("hero")
    // },
  }

  const handlers = useSwipeable(config)

  const toggleMuted = () => {
    setVideoMuted(previousState => !previousState)
  }

  const data = useStaticQuery(graphql`
    query EditorialPathsQueryMenu {
      allEditorialJson {
        edges {
          node {
            slug
            key
            language
          }
        }
      }
      allCategoryJson {
        edges {
          node {
            slug
            key
            language
          }
        }
      }
    }
  `)

  return (
    <div {...handlers}>
      <Seo title="Ardaraz" />
      <Element name="hero">
        <div style={{ height: `100vh`, width: "100%", position: "relative" }}>
          <Video
            mobileSrc="/ardaraz.mp4"
            desktopSrc="/ardaraz-desktop.mp4"
            muted={videoMuted}
          />
          <SoundButton muted={videoMuted} toggleSound={toggleMuted} />
          <MenuBottom
            first={undefined}
            second={
              <div className={"bottom-menu-buttom beauty"}>
                <Link
                  className="nav--item"
                  to={getSlugByKey(data, "beauty", language)}
                  children={t("menu.beauty")}
                />
              </div>
            }
            third={
              <div className={"bottom-menu-buttom sex"}>
                <Link
                  className="nav--item"
                  to={getSlugByKey(data, "sex", language)}
                  children={t("menu.sex")}
                />
              </div>
            }
            fourth={undefined}
          />
        </div>

        <div className="content-arrow arrow-down absolute content-arrow-none">
          <img
            className="bounce"
            src={arrowDownSVG}
            alt="Arrow down"
            onClick={scrollToCategories}
          />
        </div>
      </Element>

      {showCarousel && (
        <Element name="categories">
          <CategoriesCarousel refCategories={refCategories} />
        </Element>
      )}
    </div>
  )
}

export default Home
